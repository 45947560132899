import React, { FC, useEffect, useState } from 'react';
import { HttpClient, WidgetProps } from '@wix/yoshi-flow-editor';
import { st, classes } from './Widget.st.css';
import {
  Card,
  ImageResizeOptions,
  Text,
  TextTypography,
  ThumbnailImage,
  TextField,
  TextFieldTheme,
  Dropdown,
  DropdownTheme,
  FloatingDropdown,
  ThreeDotsLoader,
  Button,
  ButtonPriority,
  ButtonSize,
  Image,
} from 'wix-ui-tpa';
import { Search } from 'wix-ui-icons-common';
import { queryProduct } from '@wix/ambassador-bazaar-v1-product/build/cjs/http';
import {
  Product,
  SortOrder,
} from '@wix/ambassador-bazaar-v1-product/build/cjs/types';

export type ControllerProps = {
  instance: string;
  navigateToAddListing: any;
  currentMember: any;
  baseUrl: string;
};

const locations = [
  { id: 'any', value: 'Any', isSelectable: true },
  { id: 'vilnius', value: 'Vilnius', isSelectable: true },
  { id: 'us', value: 'United States', isSelectable: true },
  { id: 'krakow', value: 'Krakow', isSelectable: true },
  { id: 'telaviv', value: 'Tel Aviv', isSelectable: true },
];

const sortItems = [
  { id: 'newest', value: 'Newest', isSelectable: true },
  { id: 'asc', value: 'Price - Low to High', isSelectable: true },
  { id: 'desc', value: 'Price - High to Low', isSelectable: true },
  { id: 'az', value: 'Name - A to Z', isSelectable: true },
  { id: 'za', value: 'Name - Z to A', isSelectable: true },
];

const owners = [
  { id: 'any', value: 'All Products', isSelectable: true },
  { id: 'me', value: 'My Products', isSelectable: true },
];

const Widget: FC<WidgetProps<ControllerProps>> = ({
  instance,
  navigateToAddListing,
  currentMember,
  baseUrl,
}) => {
  const [sort, setSort] = useState(sortItems[0] as any);
  const [owner, setOwner] = useState(owners[0] as any);
  const [products, setProducts] = useState([] as any);
  const [search, setSearch] = useState('' as any);
  const [location, setLocation] = useState('' as any);

  useEffect(() => {
    const fetchData = async () => {
      let filter: any = { $and: [] };
      let sorting: any = {};

      if (location && location !== 'any') {
        filter = { $and: [...filter.$and, { location: { $eq: location } }] };
      }

      if (sort) {
        const sortOptions: any = {
          newest: [{ fieldName: 'createdDate', order: SortOrder.DESC }],
          asc: [{ fieldName: 'price.value', order: SortOrder.ASC }],
          desc: [{ fieldName: 'price.value', order: SortOrder.DESC }],
          az: [{ fieldName: 'name', order: SortOrder.ASC }],
          za: [{ fieldName: 'name', order: SortOrder.DESC }],
        };
        sorting = sortOptions[sort.id];
      }

      if (owner.id === 'me') {
        filter = {
          $and: [...filter.$and, { memberId: { $eq: currentMember.id } }],
        };
      }

      if (search) {
        filter = { $and: [...filter.$and, { name: { $startsWith: search } }] };
      }

      const requestOptions = queryProduct({
        query: {
          filter,
          sort: sorting,
        },
      })({
        isSSR: false,
        host: 'NA',
      });
      const httpClient = new HttpClient({
        baseURL: '/_api/bazaar/',
        headers: { Authorization: instance },
      });
      const prod: any = await httpClient.request(requestOptions);
      setProducts(prod.data.products);
    };

    fetchData();
  }, [search, location, sort, owner]);

  if (!products) {
    return <ThreeDotsLoader />;
  }

  return (
    <div className={st(classes.root, {})} data-hook="FeedPage-wrapper">
      <div className={st(classes.filters)}>
        <div className={st(classes.location)}>
          <Dropdown
            options={locations}
            theme={DropdownTheme.Line}
            placeholder="Location"
            onChange={(l) => setLocation(l.id)}
          />
        </div>

        <div className={st(classes.sort)}>
          <FloatingDropdown
            value={sort.id}
            onChange={(s) => setSort(s)}
            options={sortItems}
            label="Sort by: "
            placeholder={sort.value}
          />
        </div>

        <div className={st(classes.sort)}>
          <FloatingDropdown
            value={owner.id}
            onChange={(s) => setOwner(s)}
            options={owners}
            label="Owner: "
            placeholder={owner.value}
          />
        </div>

        <div className={st(classes.search)}>
          <TextField
            theme={TextFieldTheme.Line}
            prefix={<Search />}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for items..."
          />
        </div>
        <div className={st(classes.addListing)}>
          <Button
            priority={ButtonPriority.secondary}
            size={ButtonSize.small}
            onClick={() => navigateToAddListing()}
          >
            Add New Listing
          </Button>
        </div>
      </div>
      <div className={st(classes.rowContainer)}>
        {products.map((item: Product) => {
          return (
            <a
              href={`${baseUrl}/product/${item.id}${window.location.search}`}
              className={st(classes.card)}
            >
              <Card className={st(classes.root, {}, classes.stacked)} stacked>
                <Card.Container className={classes.media}>
                  <Image
                    src={
                      item?.images?.[0]?.url ||
                      'https://raw.githubusercontent.com/julien-gargot/images-placeholder/master/placeholder-landscape.png'
                    }
                    alt="My product"
                    width={400}
                    height={172}
                    fluid
                    resize={ImageResizeOptions.contain}
                    className={st(classes.image)}
                  />
                </Card.Container>
                <Card.Container className={classes.info}>
                  <>
                    <Text
                      className={classes.title}
                      typography={TextTypography.smallTitle}
                      tagName="h3"
                    >
                      {item.name}
                    </Text>
                    <Text
                      className={classes.price}
                      typography={TextTypography.listText}
                      tagName="p"
                    >
                      Price:{' '}
                      {Number(item.price?.value)
                        ? item.price?.value + ' ' + item.price?.currency
                        : 'Free'}
                    </Text>
                  </>
                </Card.Container>
              </Card>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Widget;
